import React, {useEffect} from "react";


const GamesPage = () => {
  useEffect(() => {
    const urlParams = new URL(window.location.href);
    const gameMode = urlParams.searchParams.get('game_id') ?? 'crash';
    let token = urlParams.searchParams.get('token');
    let currency = urlParams.searchParams.get('currency');
    const operator = urlParams.searchParams.get('operator') ?? 'ee2013ed-e1f0-4d6e-97d2-f36619e2eb52';
    const play_mode = urlParams.searchParams.get('play_mode');
    const lang = urlParams.searchParams.get('lang') ?? 'en';

    let operatorId: string = operator;
    switch (operator) {
      case 'pokerdom': operatorId = '169102bd-f423-468c-a461-8ff45517954d'; break;
      case 'joker': operatorId = 'fbbc66a4-08f7-4dbf-9507-ced7f43b66b5'; break;
      case 'zaza': operatorId = '83554cc3-5ef5-4c97-b9bb-ddbe35a02ddd'; break;
    }

    let gameUrl: string;

    if (play_mode && play_mode === 'demo') {
      operatorId = 'ee2013ed-e1f0-4d6e-97d2-f36619e2eb52';
      gameUrl = `https://api.inout.games/api/launch?gameMode=${gameMode}&operatorId=${operatorId}&currency=USD&lang=${lang}&adaptive=true`;
    } else {
      gameUrl = `https://api.inout.games/api/launch?operatorId=${operatorId}&gameMode=${gameMode}&authToken=${token}&currency=${currency}&lang=${lang}&adaptive=true`;
    }

    window.location.href = gameUrl;
  }, []);

  return (
    <></>
  );
};

export default GamesPage;
